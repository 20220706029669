.MuiBox-root {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.MuiList-root {
  max-height: 320px;
  overflow-x: hidden;
  overflow-y: auto;
}

/* Kepler GL Styles */
.bottom-widget--container {
  display: none !important;
}

.map-popover {
  display: none !important;
}